<template>
  <div>
    <b-card header="Duplicate Session">
      <spinner-loader :loading="isLoading" />

      <session-form
        v-if="!isLoading"
        :submit="duplicateSession"
        :session.sync="session"
        :event="eventData"
      />
    </b-card>
  </div>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SessionForm from '@/common/components/QuickEvents/Sessions/SessionForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'DuplicateSession',
  components: {
    SessionForm,
    SpinnerLoader,
  },
  data() {
    return {
      session: {},
      eventData: {},
      loading: true,
    }
  },
  computed: {
    eventFormatted() {
      return {
        topic: this.session.topic,
        speaker: this.session.speaker,
        url: this.session.url,
        venue: this.session.venue,
        language: this.session.language,
        start_time: this.session.start_time,
        end_time: this.session.end_time,
        capacity: this.session.capacity,
        target_gender: this.session.target_gender,
        max_male_number: this.session.max_male_number,
        max_female_number: this.session.max_female_number,
        auto_approval: this.session.auto_approval,
        vaccination_required: this.session.vaccination_required,
        status: this.session.status,
        attendees_name_required: this.session.attendees_name_required,
        general_notes: this.session.general_notes,

        restriction_notes: this.session.restriction_notes?.map(note => note.note).filter(note => note),

        age_segments: this.session.age_segments?.filter(segment => segment.from && segment.to && segment.capacity),

        additional_services: this.isSessionPaid
          ? this.session.paidTicket.additional_services?.filter(service => service.title && service.price)
          : [],

        ...(this.isSessionPaid ? this.session.paidTicket : { paid: this.session.paidTicket.paid }),
      }
    },
    isSessionPaid() {
      return this.session.paidTicket.paid === 'paid'
    },
    isLoading() {
      return this.loading && !this.eventData.length
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  created() {
    this.getSession()
  },
  methods: {
    duplicateSession() {
      return this.$activities.post(`/internalops/events/${this.$route.params.eventId}/sessions`,
        this.eventFormatted).then(() => {
        this.successfulOperationAlert('Session is duplicated successfully')
        this.$router.push({ name: 'quick-event-sessions-list' })
      })
    },
    getSession() {
      return this.$activities.get(`/internalops/events/${this.$route.params.eventId}/sessions/${this.$route.params.id}`).then(res => {
        const { session, event } = res.data.data

        this.session = {
          ...session,
          language: session.language_id,
          paidTicket: {
            paid: session.paid,
            price: session.price,
            tax_ratio: session.tax_ratio,
            charity_deduction_type: session.charity_deduction_type,
            charity_deduction_amount: session.charity_deduction_amount,
            donation_deduction_type: session.donation_deduction_type,
            donation_deduction_amount: session.donation_deduction_amount,
            payment_method: session.payment_method,
            additional_services: session.price ? session.additional_services : [],
            user_covers_comissions: session.user_covers_comissions,
          },
        }

        this.eventData = {
          startTime: event.start_date,
          endTime: event.end_date,
          presenceType: event.presence_type,
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
